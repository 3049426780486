import React from 'react'
import img from '../../images/oczyszczanie-wodorowe-cialo.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const OczyszczanieWodoroweCialo = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Oczyszczanie Wodorowe | Modelowanie Sylwetki'
                    description='Wodorowe oczyszczanie pleców to wieloetapowy zabieg kompleksowej
          pielęgnacji skóry, rozpoczynając od jej głębokiego oczyszczenia,
          poprzez złuszczenie martwego naskórka, aż po dotlenienie, odżywienie i
          rewitalizację skóry.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/oczyszczanie-wodorowe'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Oczyszczanie Wodorowe</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='Oczyszczanie Wodorowe zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Wodorowe oczyszczanie pleców to wieloetapowy
                                        zabieg kompleksowej pielęgnacji skóry,
                                        rozpoczynając od jej głębokiego oczyszczenia,
                                        poprzez złuszczenie martwego naskórka, aż po
                                        dotlenienie, odżywienie i rewitalizację skóry.
                                        Podczas zabiegu wykonywana jest hydrabrazja,
                                        infuzja tlenowa, ultradźwięki oraz fala radiowa.
                                        Wodorowe oczyszczanie pleców jest zalecane zarówno
                                        kobietom, jak i mężczyznom niezależnie od pory
                                        roku. Można je stosować do każdego rodzaju i typu
                                        cery, nawet przy cerze trądzikowej, wrażliwej lub
                                        naczynkowej.
                                    </p>
                                    <p>
                                        Dzięki oczyszczaniu wodorowemu pleców uzyskuje się
                                        efekt skóry idealnie oczyszczonej, odświeżonej,
                                        zdrowej i zregenerowanej. Szczególnie zaleca się
                                        go dla cery szarej i zmęczonej, o nierównym
                                        kolorycie, w przypadku nadmiernego wydzielania
                                        sebum, czy przy występowaniu trądziku, zaskórników
                                        i rozszerzonych porów. Pojedynczy zabieg trwa
                                        minimum 45 minut a jego długość zależy od
                                        wielkości pola zabiegowego i stanu skóry.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>

                                        <h2>OCZYSZCZANIE WODOROWE - PLECY LUB DEKOLT</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    OCZYSZCZANIE WODOROWE - KAWITACJA,
                                                    WODOROWE, MASKA
                                                </p>
                                                <span className='prices'>347 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    OCZYSZCZANIE WODOROWE - KAWITACJA,
                                                    WODOROWE, MASKA, KWAS
                                                </p>
                                                <span className='prices'>397 zł</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/beautiful-young-woman-towel-sitting-floor-stroking-her-beauty-legs_10881095.htm'>
                    Image by valuavitaly
                </a>{' '}
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default OczyszczanieWodoroweCialo
